import React from "react";

import { toProperCase, transformToPhone } from "@utils/transform";
import { LeadInfoCardTableRow } from "./Base";
import { useLeadState } from "@leads/context";
import EditableInput from "~/components/EditableInput";

export const ContactInformation = () => {
	const {
		state: { lead, loading, error, print },
	} = useLeadState();

	const data = {
		title: "Contact Information",
		data: lead,
		columns: [
			{
				width: 200,
				cell: (val: any) => val,
			},
			{
				width: "100%",
				cell: (val: any) => val,
				style: { paddingLeft: 15 },
			},
		],
		selectors: [
			{
				property: "phoneNumber",
				display: "Cell Phone:",
				format: (val: string) => {
					return (
						<EditableInput
							type="phoneNumber"
							value={val}
							leadId={lead?.id}
						/>
					);
				},
				copy: true,
			},
			{
				property: "emailAddress",
				display: "Email:",
				copy: true,
			},
			{
				property: "creditApp.preferredContactMethod",
				display: "Contact Method:",
				format: toProperCase,
			},
		],
		loading,
		error,
		print,
	};

	return (
		<>
			<LeadInfoCardTableRow {...data} />
		</>
	);
};
