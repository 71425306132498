import React, { ReactElement } from "react";

import { InfoCardTableRow } from "~/components/InfoCard";

import { IFlexTableRowsProps } from "~/components/FlexTable/FlexTable";
import { IInfoCardProps } from "~/components/InfoCard/InfoCard";
import get from "lodash/get";

interface Selector {
	property: string | string[];
	display: string;
	format?: (val?: any) => string | ReactElement;
	join?: string;
	copy?: boolean;
}

interface LeadInfoCardTableProps extends IInfoCardProps {
	data: any;
	selectors: Selector[];
	loading: boolean;
	print: boolean;
}

export const LeadInfoCardTableRow: React.FC<
	Omit<IFlexTableRowsProps, "rows" | "columns"> & LeadInfoCardTableProps
> = (props: LeadInfoCardTableProps) => {
	const rows = props.selectors.map((selector) => {
		const value =
			selector.property && Array.isArray(selector.property)
				? selector.property
						.map((p: string) => get(props.data, p))
						.join(selector.join)
				: get(props.data, selector.property);
		const formattedValue = selector.format ? selector.format(value) : value;
		return {
			cells: [selector.display, formattedValue],
			copy: selector.copy,
		};
	});

	const tableProps = {
		minHeight: 370,
		columns: [
			{
				width: props.print ? "35%" : "20%",
				cell: (val: any) => val,
			},
			{
				width: props.print ? "65%" : "80%",
				cell: (val: any) => val,
				style: { paddingLeft: 15 },
			},
		],
		rows,

		...props,
	};

	return <InfoCardTableRow {...tableProps} />;
};
