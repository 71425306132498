import React from "react";

import { LeadInfoCardTableRow } from "./Base";
import { useLeadState } from "@leads/context";
import { rowCardColumnsFixedLong } from "@leads/defaults";

interface Props {}

export const MarketingInformation = (props: Props) => {
	const {
		state: { lead, loading, error, print },
	} = useLeadState();

	const data = {
		title: "Marketing Information",
		tableOpts: {
			columnWrap: {
				height: 244,
			},
			rowWidth: "auto",
		},
		columns: rowCardColumnsFixedLong,
		selectors: [
			{
				property: "leadCampaign.utmCampaign",
				display: "UTM Campaign:",
			},
			{
				property: "leadCampaign.utmContent",
				display: "UTM Content:",
			},
			{
				property: "leadCampaign.utmMedium",
				display: "UTM Medium:",
			},
			{
				property: "leadCampaign.utmSource",
				display: "UTM Source:",
			},
			{
				property: "leadCampaign.s1",
				display: "S1:",
			},
			{
				property: "leadCampaign.s2",
				display: "S2:",
			},
			{
				property: "leadCampaign.s3",
				display: "S3:",
			},
			{
				property: "leadCampaign.s4",
				display: "S4:",
			},
			{
				property: "leadCampaign.s5",
				display: "S5:",
			},
		],

		data: lead,
		loading,
		error,
		print,
	};

	return (
		<>
			<LeadInfoCardTableRow {...data} />
		</>
	);
};
