import { CardInstructions, ExtendCardInstructions } from "~/model/card";

import type { FlexTableColumnDef } from "~/components/FlexTable";
import type { LeadDetails } from "~/model/leads";
import React from "react";

export const defaultColumns = ({
	col1,
	col2,
}: {
	col1?: React.CSSProperties;
	col2?: React.CSSProperties;
} = {}): FlexTableColumnDef[] => [
	{
		width: "20%",
		style: col1,
	},
	{
		width: "80%",
		style: col2,
	},
];

export const rowCardColumnsFluid = defaultColumns({
	col1: {
		paddingRight: 5,
	},
	col2: {
		fontWeight: "bold",
	},
});

export const rowCardColumnsFixedShort = defaultColumns({
	col1: {
		minWidth: "140px",
		paddingRight: "5px",
	},
	col2: {
		fontWeight: "bold",
	},
});

export const rowCardColumnsFixedLong = defaultColumns({
	col1: {
		minWidth: "200px",
		paddingRight: "5px",
	},
	col2: {
		fontWeight: "bold",
	},
});

export const rowCardColumnsEqualWidth = defaultColumns({
	col1: {
		width: "50%",
	},
	col2: {
		width: "50%",
		fontWeight: "bold",
	},
});

export const smallAttrRowCard = <P = LeadDetails>(
	args: ExtendCardInstructions<P, "rows", LeadDetails>,
): CardInstructions<P, LeadDetails> => ({
	type: "rows",
	getProps: (consumer) => (consumer || {}) as unknown as P,
	layout: "2-column",
	columns: rowCardColumnsFixedShort,
	minHeight: 175,
	...args,
});

export const smallAttrRowCardFluidColumn = <P = LeadDetails>(
	args: ExtendCardInstructions<P, "rows", LeadDetails>,
): CardInstructions<P, LeadDetails> => ({
	type: "rows",
	getProps: (consumer) => (consumer || {}) as unknown as P,
	layout: "2-column",
	columns: rowCardColumnsEqualWidth,
	minHeight: 175,
	...args,
});

export const largeAttrRowCard = <P = LeadDetails>(
	args: ExtendCardInstructions<P, "rows", LeadDetails>,
): CardInstructions<P, LeadDetails> =>
	smallAttrRowCard({
		layout: "full-width",
		...args,
	});

export const smallAttrRowEachCard = <P = LeadDetails>(
	args: ExtendCardInstructions<P, "each", LeadDetails>,
): CardInstructions<P, LeadDetails> => ({
	type: "each",
	getProps: (consumer) => (consumer || {}) as unknown as P,
	layout: "2-column",
	columns: rowCardColumnsFixedShort,
	minHeight: 175,
	...args,
});

export const largeAttrRowEachCard = <P = LeadDetails>(
	args: ExtendCardInstructions<P, "each", LeadDetails>,
): CardInstructions<P, LeadDetails> =>
	smallAttrRowEachCard({
		layout: "full-width",
		...args,
	});

export const smallGroupRowCard = <P = LeadDetails>(
	args: ExtendCardInstructions<P, "groups", LeadDetails>,
): CardInstructions<P, LeadDetails> => ({
	type: "groups",
	columns: defaultColumns({
		col1: {
			width: "200px",
			paddingRight: "5px",
		},
	}),
	layout: "2-column",
	loadingRows: 4,
	...args,
});

export const largeGroupRowCard = <P = LeadDetails>(
	args: ExtendCardInstructions<P, "groups", LeadDetails>,
): CardInstructions<P, LeadDetails> =>
	smallGroupRowCard({
		layout: "full-width",
		...args,
	});

export const smallCustomCard = <P = LeadDetails>(
	args: ExtendCardInstructions<P, "custom", LeadDetails>,
): CardInstructions<P, LeadDetails> => ({
	type: "custom",
	columns: [],
	layout: "2-column",
	minHeight: 370,
	...args,
});

export const largeCustomCard = <P = LeadDetails>(
	args: ExtendCardInstructions<P, "custom", LeadDetails>,
): CardInstructions<P, LeadDetails> =>
	smallCustomCard({
		layout: "full-width",
		...args,
	});
