import { makeStyles } from "@material-ui/core";

export const customStyles = makeStyles((theme) => ({
	root: {
		display: "block",
		width: "100%",
	},
	rootContainer: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		width: "100%",
		gap: 24,
	},
	inputContainer: {
		background: "#F8F8F8",
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		width: "100%",
		gap: 8,
		borderRadius: 4,
	},
	inputActions: {
		display: "flex",
		gap: 8,
		alignItems: "center",
	},
	icon: {
		cursor: "pointer",
	},
	editIcon: {
		cursor: "pointer",
		height: 18,
		"@media print": {
			display: "none",
		},
		[theme.breakpoints.down(theme.breakpoints.values.md)]: {
			display: "none",
		},
	},
	input: {
		border: "none",
		background: "#F8F8F8",
		fontWeight: "bold",
		"&:focus-visible": {
			outline: "none",
		},
	},
	inputError: {
		fontWeight: "normal",
		color: "#D32030",
		fontSize: 12,
	},
}));
