import React from "react";

import { createLeadView, LeadViewComponent } from "../_lib/view";

import { LeadStateProvider } from "../_lib/context";

import { PersonalInformation } from "@leads/cards/PersonalInformation";
import { ContactInformation } from "@leads/cards/ContactInformation";
import { LeadInformation } from "@leads/cards/LeadInformation";
import { LeadHeader } from "@leads/LeadHeader";
import { MarketingInformation } from "@leads/cards/MarketingInformation";
import { Grid, GridItem } from "@leads/Grid";

const BasicView: LeadViewComponent = (props) => {
	return (
		<>
			<LeadHeader {...props} />

			<Grid>
				<GridItem item>
					<PersonalInformation />
				</GridItem>
				<GridItem item>
					<ContactInformation />
				</GridItem>
				<GridItem item>
					<LeadInformation />
				</GridItem>
				<GridItem item sm={12}>
					<MarketingInformation />
				</GridItem>
			</Grid>
		</>
	);
};

const LeadView: LeadViewComponent = (props) => {
	const layoutProps = {
		...props,
		pageProps: props.params,
		title: "Lead Details",
		showBack: true,
		backTo: "/leads/",
	};

	return (
		<LeadStateProvider {...layoutProps}>
			<BasicView {...layoutProps} />
		</LeadStateProvider>
	);
};

export default createLeadView(LeadView, {});
