import React from "react";

import { timestampToDateTime, toProperCase } from "@utils/transform";
import { LeadInfoCardTableRow } from "./Base";
import dayjs from "dayjs";
import { useLeadState } from "@leads/context";

interface Props {}

const toMonthlyBudget = (key: string) =>
	[
		{
			key: "over-500",
			value: "Over $500 / Month",
		},
		{
			key: "375-500",
			value: "$375 - $500 / Month",
		},
		{
			key: "250-375",
			value: "$250 - $375 / Month",
		},
		{
			key: "under-250-month",
			value: "Under $250 / Month",
		},
	].find((budget) => budget.key === key)?.value || "";

export const LeadInformation = (props: Props) => {
	const {
		state: { lead, loading, error, print },
	} = useLeadState();

	const data = {
		title: "Lead Information",
		columns: [
			{
				width: 200,
				cell: (val: any) => val,
			},
			{
				width: "100%",
				cell: (val: any) => val,
				style: { paddingLeft: 15 },
			},
		],
		selectors: [
			{
				property: "createdAt",
				display: "Date Applied:",
				format: (val: string) => timestampToDateTime(dayjs(val)),
			},
			{
				property: "sourceUrl",
				display: "Lead Referral:",
				format: (val: string) =>
					val ? (
						<a href={val} rel="noreferrer" target="_blank">
							{val}
						</a>
					) : (
						""
					),
			},
			{
				property: "creditApp.desiredVehicle",
				display: "Desired Vehicle:",
				format: toProperCase,
			},
			{
				property: "additionalInformation.monthlyBudget",
				display: "Monthly Budget:",
				format: toMonthlyBudget,
			},
		],
		data: lead,
		loading,
		error,
		print,
	};

	return (
		<>
			<LeadInfoCardTableRow {...data} />
		</>
	);
};
