import React, { useEffect, useState } from "react";

import { transformToPhone } from "@utils/transform";

import { useLeadActions } from "@api/leads";

import { useFeedbackContext } from "~/components/Feedback/context";

import { emailValidator } from "@autocorp/web-core-utils/lib/emailValidator";

import Accept from "~/assets/vectors/tick.svg";
import Cancel from "~/assets/vectors/x.svg";
import Edit from "~/assets/vectors/edit-input.svg";

import { customStyles } from "./styles";

import { IEditableInput } from "./types";

const formatPhoneNumber = (phoneNumber: string) => {
	return phoneNumber.replace(/[()\-|\s]/g, "");
};

const inputFormat = (type: string, input: string) => {
	switch (type) {
		case "phoneNumber":
			return transformToPhone(input, true);
		default:
			return input;
	}
};

const outputFormat = (type: string, input: string) => {
	switch (type) {
		case "phoneNumber":
			return formatPhoneNumber(input);
		case "emailAddress":
		default:
			return input;
	}
};

const useInputValidation = () => {
	const [validationError, setInputError] = useState("");

	const checkValidation = (type: string, input: string) => {
		setInputError("");
		if (type === "emailAddress" && input && !emailValidator.test(input)) {
			setInputError("Please enter a valid email address");
			return false;
		}
		if (!input) {
			setInputError("Please enter a valid value!");
			return false;
		}

		return true;
	};

	return { validationError, checkValidation };
};

const maxLength = (type: string) => {
	switch (type) {
		case "phoneNumber":
			return 17;
		default:
			return undefined;
	}
};

const EditableInput = ({
	value,
	error,
	onSubmit,
	leadId,
	type,
}: IEditableInput) => {
	const [isEditMode, setEditMode] = useState(false);
	const { validationError, checkValidation } = useInputValidation();
	const [inputValue, setValue] = useState<string | undefined>(value || "");
	const styles = customStyles();

	useEffect(() => {
		setValue(value);
	}, [type, value]);

	const { updateLeadField } = useLeadActions(leadId);
	const { handleOpenFeedback } = useFeedbackContext();

	const input = inputFormat(type, inputValue || "");

	const handleSubmit = async () => {
		try {
			const isValid = checkValidation(type, input);
			if (!isValid) {
				return;
			}

			const output = outputFormat(type, input);
			await updateLeadField(type, output);

			handleOpenFeedback({
				message: "Your changes has been saved!",
				severity: "success",
			});
			setEditMode(false);
		} catch (error) {
			console.error(error);
		}
	};

	const handleCancel = () => {
		setValue(value);
		setEditMode(false);
	};

	if (isEditMode) {
		return (
			<div className={styles.root}>
				<div className={styles.inputContainer}>
					<input
						placeholder={value}
						className={styles.input}
						value={input}
						onChange={(event) => setValue(event.target.value)}
						maxLength={maxLength(type)}
					/>
					<div className={styles.inputActions}>
						<Accept
							className={styles.icon}
							onClick={onSubmit ? onSubmit : handleSubmit}
						/>
						<Cancel
							className={styles.icon}
							onClick={handleCancel}
						/>
					</div>
				</div>
				<span className={styles.inputError}>
					{error || validationError}
				</span>
			</div>
		);
	}
	return (
		<div className={styles.rootContainer}>
			{input}
			<Edit
				className={styles.editIcon}
				onClick={() => setEditMode(true)}
			/>
		</div>
	);
};

export default EditableInput;
