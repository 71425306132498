import React, { CSSProperties, Fragment } from "react";

import { toUpper } from "lodash";

import { dateTransformer, toProperCase } from "@utils/transform";

import { dateFormat } from "~/meta";

import { useLeadState } from "@leads/context";

import { LeadInfoCardTableRow } from "./Base";
import { JourneyStage, Lead, LeadJourney } from "@api/graphql/types";
import { Box, Tooltip } from "@material-ui/core";
import { Typography } from "~/components/Typography";

export const PersonalInformation = () => {
	const {
		state: { lead, loading, error, print },
	} = useLeadState();

	const defaultStyles: CSSProperties = {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		height: 24,
		width: 24,
		borderRadius: 4,
		color: "#FFFFFF",
		backgroundColor: "#262B30",
		cursor: "pointer",
	};

	const isFullApp = (lead: Lead) => {
		if (
			lead?.leadJourneys?.nodes.find(
				(j: LeadJourney) => j.stage === JourneyStage.CreditFull,
			)
		) {
			return (
				<Tooltip title="Full Application" placement="top">
					<div style={defaultStyles}>F</div>
				</Tooltip>
			);
		}
		return null;
	};

	const isMSA = (lead: Lead) => {
		if (lead?.meta?.productVariant === "msa") {
			return (
				<Tooltip title="MSA" placement="top">
					<div style={defaultStyles}>M</div>
				</Tooltip>
			);
		}
		return null;
	};

	const Header = () => {
		return (
			<Box style={{ display: "flex", gap: 18 }}>
				Personal Information
				{/* <Box style={{ display: "flex", gap: 8 }}>
					{isFullApp(lead)}
					{isMSA(lead)}
				</Box> */}
			</Box>
		);
	};

	const data = {
		title: <Header />,
		data: lead,
		columns: [
			{
				width: 200,
				cell: (val: any) => val,
			},
			{
				width: "100%",
				cell: (val: any) => val,
				style: { paddingLeft: 15 },
			},
		],
		selectors: [
			{
				property: ["firstName", "lastName"],
				join: " ",
				display: "Full Name:",
				format: toProperCase,
			},
			{
				property: "dateOfBirth",
				display: "Date of Birth:",
				format: dateTransformer(dateFormat),
			},
			{
				property: ["streetNumber", "street"],
				join: " ",
				display: "Address:",
				cell: ([streetNumber, street]: [string, string]) => (
					<>{toProperCase([streetNumber, street].join(" "))}</>
				),
			},
			{
				property: "city",
				display: "City:",
				// format: (val: string) => {
				//     return <EditableInput type="city" value={val} leadId={lead?.id}  />;
				// },
			},
			{
				property: "provinceCode",
				display: "Province:",
				format: toUpper,
			},
			{
				property: "postalCode",
				display: "Postal Code:",
				format: (val: string) =>
					(val && val.replace(/(.{3})/g, "$1 ").trim()) || "",
			},
		],
		loading,
		error,
		print,
	};

	return (
		<Fragment>
			<LeadInfoCardTableRow {...data} />
		</Fragment>
	);
};
